import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'ISP Corner bracket bolted to SOG' },
    { img: data.i2, caption: 'Epicore MSR 20 ga with ISP' },
    { img: data.i3, caption: 'ISP Greased Rod before pour' },
    { img: data.i4, caption: 'ISP Thru-bolt Driven Up Through Concrete Pour' },
    { img: data.i5, caption: 'ISP Floor to Floor bolt- thru' },
    { img: data.i6, caption: 'ISP Full panel installed' },
  ];

  return (
    <Layout>
      <SEO title="System Components: Infinity Shear Panels" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Components</h2>
        <h1 className='title title--xxxl color-primary'>Infinity Shear Panels</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>The Infinity Shear Panel (ISP) may be a good option to carry the lateral loads in a
          building up to about 5-Stories.  Since our first projects in 1986, we had been fighting
          the myriad of problems associated with using light gage flat x-strapping to carry the
          lateral loads. The flat straps were getting cut out by mechanical and electrical subs.
          They would deflect and become “wavy” creating bulges in the drywall and become a problem
          for drywall finishing. They were surface applied to the face of the wall which created
          material thickness buildup and potential finishing problems even if they remained flat.
          Many designs require extensive, time consuming uplift connections (drilling through slabs,
          Simpsons, gusset plates, etc.) and there was no way to tighten them after installation
          as the building got loaded.  Our Infinity Shear Panel solves all of those problems.</p>

          <p>Our ISP uses 0.75” round steel rods creating an X within the ISP itself rather
          than surface applying any material so there are no bulges or finishing problems.
           Our ISP Corner Bracket is a custom steel casting that is designed to anchor the
          diagonal rods.  The ISP Corner Bracket has the inherent advantage of being able
          to effortlessly thru-bolt from floor-to-floor for uplift as the floors are being
          poured rather than having to drop back later and drill the slab, etc.  It is
          standardized in approximately 10 foot lengths to allowable loads from 7.5 kips to 9.4
          kips, so it is easy for the Engineer to integrate into the structural design.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-components/infinity-shear-panels/gallery/01-ISPSP130_(33956).png" }) {
            ...GalleryCarouselImageFragment },
        i2: file(relativePath: { eq: "system-components/infinity-shear-panels/gallery/02-MSRDK070_(34257).png" }) {
            ...GalleryCarouselImageFragment },
        i3: file(relativePath: { eq: "system-components/infinity-shear-panels/gallery/03-ISPSP030_(34252).png" }) {
            ...GalleryCarouselImageFragment },
        i4: file(relativePath: { eq: "system-components/infinity-shear-panels/gallery/04-ISPSP070_(34364).png" }) {
            ...GalleryCarouselImageFragment },
        i5: file(relativePath: { eq: "system-components/infinity-shear-panels/gallery/05-ISPSP110_(33936).png" }) {
            ...GalleryCarouselImageFragment },
        i6: file(relativePath: { eq: "system-components/infinity-shear-panels/gallery/06-ISPSP120_(33944).png" }) {
            ...GalleryCarouselImageFragment },
    }
`
